import { Component, OnInit, Inject, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from '@fuse/services/common.service';
import { AddEventTypeService } from './add-event-type.service';
import { DomSanitizer } from "@angular/platform-browser";
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-add-dialog',
  templateUrl: './add-event-type-dialog.component.html',
  styleUrls: ['./add-event-type-dialog.component.scss']
})
export class AddEventTypeDialogComponent implements OnInit {

  previewUrl;
  previewUrlBg;

  imageFile;
  imageFileBg;

  isImageValid = true;
  isImageValidBg = true;

  form: FormGroup;
  heading: string;
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef

  constructor(
    private _fb: FormBuilder,
    public matDialogRef: MatDialogRef<AddEventTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _commonService: CommonService,
    private _snackbar: MatSnackBar,
    private _addEventTypeService: AddEventTypeService,
    private sanitizer: DomSanitizer,
    private _matDialog: MatDialog
  ) {
    if (this._data.heading)
      this.heading = this._data.heading;
    // console.log("this.heading",this.heading);

  }
  ngOnInit() {
    if (this._data.eventType) {
      this.createForm(this._data.eventType)
      this.heading = 'Edit new event type'
    }
    else
      this.createForm()
  }

  createForm(data?) {
    this.form = this._fb.group({
      "title": [data ? data.title : '', Validators.required],
      "icon": [data ? data.icon : ''],
      "background": [data ? data.backgroundImage : '', Validators.required],
      'isApprove': [data ? data.isApprove : 0]
    })
    this.previewUrl = this.form.value.icon;
    this.previewUrlBg = this.form.value.background;
  }

  //  onSubmit() {
  //   if (this._data.eventType)
  //     this._addEventTypeService.editType(this.form.value, this._data.eventType.id).then(() => {
  //       this.matDialogRef.close(true)
  //     })
  //   else
  //     this._addEventTypeService.addEventType(this.form.value).then(() => {
  //       this.matDialogRef.close(true)
  //     })
  // }

  onSubmit() {
    if (this.form.valid) {
      if (this._data?.eventCategory?.id) {
        const payload = {
          'title': this.form.value?.title,
          'icon': this.form.value?.icon || '',
          'backgroundImage': this.form.value?.background || '',
          'eventTypeId': this._data.eventCategory.id,
        }
        this._addEventTypeService.editType(payload, this._data.eventType.id).then(() => {
          this.matDialogRef.close(true)
        })
      } else {
        const payload = {
          'title': this.form.value.title,
          'icon': this.form.value.icon || '',
          'backgroundImage': this.form.value.background || '',
          'isApprove': 1
        }
        this._addEventTypeService.addEventType(payload).then(() => {
          this.matDialogRef.close(true)
        })
      }
    }
  }

  removeImage() {
    this.fileUpload.nativeElement.value = ''
    this.previewUrl = ''
    this.form.get('icon').setValue('')
  }

  // on upload Image presignedURL API
  onUploadImage(fileObj, type) {
    console.log("fileObj", fileObj);

    const payload = {
      "fileExt": String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
      "fileName": type == 'icon' ? 'category_' : 'backgroundImage_',
      "folderName": 'event/category', // it is fix set by backend.
      'mimeType': fileObj?.type
    }

    console.log("onUploadImage payload ===>", payload);
    return new Promise((resolve, reject) => {
      this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
        if (result.status == 200) {
          if (type == 'icon') {
            this.form.get('icon').setValue(result.data.imageUploadUrl);
          } else {
            this.form.get('background').setValue(result.data.imageUploadUrl);
          }

          // payload for get Image upload status 
          const payload = {
            'id': result.data.id,
            'status': 1 // fix by backend.
          }
          this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj?.type, fileObj);
          this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
            if (uploadStatusResponse.status == 200) {
              this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
              resolve(true);
            } else {
              this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
              reject();
            }
          })
        }
      })
    })
  }


  removeImageBg() {
    this.previewUrlBg = ''
    this.form.get('backgroundImage').setValue('')
  }


  readUrlIcon(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log('image type is ', file)
      if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/svg+xml' || file.type == 'image/vnd.microsoft.icon') {
        this.isImageValid = true;
      } else {
        this.isImageValid = false;
      }
      if (this.isImageValid) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString())
          console.log("(this.previewUrl", this.previewUrl)
        }
        this.imageFile = file
        // this.form.get('icon').setValue(file)
        this.onUploadImage(this.imageFile, 'icon');
      }
    }
  }


  readUrlBackground(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log('image type is ', file.type)
      if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/svg+xml' || file.type == 'image/vnd.microsoft.icon') {
        this.isImageValidBg = true;
      } else {
        this.isImageValidBg = false;
      }
      if (this.isImageValidBg) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.previewUrlBg = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString())
          console.log(this.previewUrlBg)
        }
        this.imageFileBg = file
        // this.form.get('background').setValue(file)
        this.onUploadImage(this.imageFileBg, 'background');
      }
    }
  }

}
