import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-order-members',
  templateUrl: './order-members.component.html',
  styleUrls: ['./order-members.component.scss']
})
export class OrderMembersComponent implements OnInit {

  eventName;
  transactionId;
  orderDate;
  totalAmount;
  ticketType;
  ticketNumber;

  memberName;
  memberAge;
  memberCity;
  memberContact;
  memberCountry;
  memberEmail;
  memberGender;
  memberState;
  seatNumber;
  personDetails;
  endDate;
  eventId;
  eventIdlink;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<OrderMembersComponent>
  ) {
    console.log("data is here", data.allMembers);
    /* order details */
    this.eventName = data.allMembers.eventInfo.eventName
    this.transactionId = data.allMembers.orderInfo.transactionId;
    this.orderDate = data.allMembers.eventInfo.date;
    this.endDate = data.allMembers.eventInfo.endDate;
    this.totalAmount = data.allMembers.orderInfo.orderAmount;
    this.ticketType = data.allMembers.ticketDescription.Title;
    this.ticketNumber = data.allMembers.ticketDescription.ticketNumber;
    this.eventId = data.allMembers.eventInfo.eventId;

    this.eventIdlink = 'http://localhost:4200/events/edit?id=' + this.eventId;

    /* member detials */
    this.personDetails = data.allMembers.ticketMembers;
   }

  ngOnInit() {
  }

}
