import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '@fuse/services/common.service';
import { TaxService } from 'app/main/tax/tax.service';

@Component({
  selector: 'app-add-tax',
  templateUrl: './add-tax.component.html',
  styleUrls: ['./add-tax.component.css']
})
export class AddTaxComponent implements OnInit {

  form = this.addTax.group({
    title: ['', Validators.required],
    taxRate: ['', Validators.required],
    country: ['', Validators.required]
  });
  editData: any;

  constructor(
    private addTax: FormBuilder,
    public matDialogRef: MatDialogRef<AddTaxComponent>,
    public _commonServices : CommonService,
    private _TaxService : TaxService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.editData = _data ? _data : ''; 
    
    if(this.editData){
      this.editCase();    
    }
   }

  // setting defult value in edit case
  editCase(){
    this.form.get('title').setValue(this.editData['title']);
    this.form.get('taxRate').setValue(this.editData['amount']);
    this.form.get('country').setValue(this.editData['country']);
  }

  ngOnInit() {
  }

  onSubmit(){
    if(this.editData){
      let editPayload = {
        id: this.editData['id'],
        title : this.form.get('title').value,
        amount: this.form.get('taxRate').value,
        countryId: this.form.get('country').value
      }
      this.edit(editPayload);
    }else{
      let payload = {
        title : this.form.get('title').value,
        amount: this.form.get('taxRate').value,
        countryId: this.form.get('country').value
      }
      this._TaxService.addTax(payload).then((result:any) => {
        if(result.status == 200){
          this.matDialogRef.close(result.data);
          this._TaxService.getTaxList();
        }else{
          this.matDialogRef.close();
        }
      })
    }

  }
  edit(editPayload){ 
    this._TaxService.editTax(editPayload).then((result:any) =>{
      if(result.status == 200){
        this.matDialogRef.close(result.data);
      }else{
        this.matDialogRef.close();
      }
    })
  }
    
}
