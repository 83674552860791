import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiEndPointUrl, ReqHeader } from '@fuse/utils/systemEnums';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class TaxService {

  taxList: any = new BehaviorSubject([]);
  
  constructor(
    private _httpClient: HttpClient,
    private location: Location,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matSnackBar:MatSnackBar,
    private _CommonService : CommonService
  ) {

  }

  reqHeadersWithToken = new HttpHeaders({
    'Authorization': localStorage.getItem('userTokenSA'),
    'Content-Type': 'application/json'
  });

  
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
          this.getTaxList(),
          ]).then(
            () => {
              resolve('');
            },
          reject
        );
    })
  }


    /* GET TAXLIST */
    public getTaxList(){
      this._fuseProgressBarService.show();
      return new Promise((resolve, reject) => {
        this._httpClient.get(ApiEndPointUrl + 'superAdmin/getAllTaxs', this._CommonService.getHeader()).subscribe((response:any) => {
          this._fuseProgressBarService.hide();
          if(response.status == 200){
            this.taxList.next(response.data);
          }else{
            this.taxList = [];
          }
          resolve(response)
        }, reject)
      })
    }
  
    /* ADD TAX  */
    public addTax(payload){
      this._fuseProgressBarService.show();
      return new Promise((resolve, reject) => {
        this._httpClient.post(ApiEndPointUrl + 'superAdmin/addTax', payload, this._CommonService.getHeader()).subscribe((response:any) => {
          this._fuseProgressBarService.hide();
          if(response.status == 200){
            this._fuseProgressBarService.hide();
            this._matSnackBar.open(response.message, 'OK', {
              verticalPosition: "bottom",
              duration: 2000
            })
            resolve(response);
          }else{
            this._matSnackBar.open(response.message, 'OK', {
              verticalPosition: "bottom",
              duration: 2000
            })
          }
          resolve(response)
        }, reject)
      })
    }
  
    /* UPDATE TAX */
    public editTax(payload){
      this._fuseProgressBarService.show();
      return new Promise((resolve, reject) => {
        this._httpClient.post(ApiEndPointUrl + 'superAdmin/updateTax', payload, this._CommonService.getHeader()).subscribe((response:any) => {
          if(response.status == 200){
            this._fuseProgressBarService.hide();
            this._matSnackBar.open(response.message, 'OK', {
              verticalPosition: "bottom",
              duration: 2000
            })
            this.getTaxList();
            resolve(response);
          }else{
            this._matSnackBar.open(response.message, 'OK', {
              verticalPosition: "bottom",
              duration: 2000
            })
          }
        }, reject)
      })
    }
  
    /* DELETE TAX */
    public deleteTax(payload){
      const options:any = {
        headers: this.reqHeadersWithToken,
        body: {
          id:payload
        }
      }
      this._fuseProgressBarService.show();
      return new Promise((resolve, reject) => {
        this._httpClient.delete(ApiEndPointUrl + 'superAdmin/deleteTax', options).subscribe((response:any) => {
          if(response.status == 200){
            this._matSnackBar.open(response.message, 'OK', {
              verticalPosition: "bottom",
              duration: 2000
            })
            this.getTaxList();
          }else{
            this._matSnackBar.open(response.message, 'OK', {
              verticalPosition: "bottom",
              duration: 2000
            })
          }
          this._fuseProgressBarService.hide();
          resolve(response)
        }, reject)
      })
    }

    


}