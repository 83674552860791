import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { LayoutModule } from 'app/layout/layout.module';
import { fuseConfig } from 'app/fuse-config';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AuthGuardService } from './auth-guard.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AgmCoreModule } from '@agm/core';
import { CookieService } from 'ngx-cookie-service';
import { AppHttpInterceptor } from '@fuse/services/interceptor.service';



const appRoutes: Routes = [
    
    {
        path: 'dashboard',
        loadChildren: './main/dashboard/project/project.module#ProjectDashboardModule',
        canActivate: [AuthGuardService]
        // canLoad: [AuthGuardService],
    },
    {
        path: 'event-company',
        loadChildren: './main/event-company/event-company.module#EventCompanyModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'application-layout',
        loadChildren: './main/application-layout/application-layout.module#ApplicationLayoutModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'event-categories',
        loadChildren: './main/category/category.module#CategoryModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'event-type',
        loadChildren: './main/events/event-type/event-type.module#EventTypeModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'subscriptions',
        loadChildren: './main/subscriptions/subscriptions.module#SubscriptionsModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'sms-packages',
        loadChildren: './main/sms-packages/sms-packages.module#SmsPackagesModule',
        canActivate: [AuthGuardService]
    },
       
    {
        path: 'transactions',
        loadChildren: './main/transactions/transactions.module#TransactionsModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'feedback',
        loadChildren: './main/feedback/feedback.module#FeedbackModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'settings',
        loadChildren: './main/settings/settings.module#SettingsModule',
        canActivate: [AuthGuardService]

    },
    {
        path: 'tax',
        loadChildren: './main/tax/tax.module#TaxModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'events',
        loadChildren: './main/events/events.module#EventsModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'auth',
        loadChildren: './main/pages/pages.module#PagesModule',
    },

    // Main Routes
    { path: '', redirectTo: 'auth', pathMatch: 'full' },    
    { path: 'forgot-password', redirectTo: 'auth/forgot-password', pathMatch: 'full' },
    // {path: 'lock',redirectTo: 'auth/lock',pathMatch: 'full'},
    { path: 'reset-password', redirectTo: 'auth/reset-password', pathMatch: 'full' },
    { path: '**', redirectTo: 'dashboard'  }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        GooglePlaceModule,
        BrowserModule,
        NgxMaterialTimepickerModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDosbgI6M3Iw9abM1cQTvFpOeJI3qpfIEs',
            libraries: ['places']
        }),
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true
        },
    ]
})

export class AppModule {
}
