import { HttpHeaders } from '@angular/common/http';

// export const ApiEndPointUrl = 'http://52.87.99.52:3537/api/v1/'; //staging 
// export const ApiEndPointUrl = 'https://eventapi.gtsinfosoft.com/api/v1/'; // new staging 
// export const ApiEndPointUrl = 'http://52.87.99.52:3537/api/v3/'; //Producation

// export const ApiEndPointUrl = 'https://api-stage.eventlocal.io/api/v2/'; // New Stagging
// export const ApiEndPointUrl = 'https://api-stage.eventlocal.io/api/v2/'; // New Stagging 

export const ApiEndPointUrl = 'https://api.eventlocal.io/api/v2/'; // New Producation


export const DefaultFilter = {
  "pageIndex": 1,
  "pageSize": 10,
  "search": "",
  "startDate": 0,
  "endDate": 0,
  "eventStatus": 0
}



export const ReqHeader = {
  reqHeadersJSON: {
    headers: new HttpHeaders({
      'Authorization': localStorage.getItem('userTokenSA'),
      'Content-Type': 'application/json'
    })
  },
  reqHeadersFormData: {
    headers: new HttpHeaders({
      'Authorization': localStorage.getItem('userTokenSA')
    })
  }
}

// pattern validation
export enum Pattern {
  Email = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
  Mobile = '[0-9]+',
  MobileMinLength = 10,
  MobileMaxLength = 12,
  Password = '^(?=.*?[A-Z])(?=.*?[0-9]).{6,}$'
}

export const Enums = {
  
  GENDER: {
    1: 'Male',
    2: 'Female'
  },

  FILTER: {
    1: "Daily",
    2: "Week",
    3: "Month",
    4: "Year"
  },

  EVENT_STATUS: {
    1: "Upcoming",
    2: "Postpone",
    3: "Cancel",
    4: "Ongoing",
    5: "Complete",
    6: "Draft"
  },

  TRANSACTIONTYPE: {
    1: "DEPOSIT",
    2: "REFUND"
  },

  paymentModes: {
    1: "CASH",
    2: "UPI",
    3: "NET BANKING",
    4: "WALLET",
  },

  NOTIFICATIONFILTER: {
    1: "DISTRIBUTOR",
    2: "EVENT NOTIFICATION",
    3: "ORDER",
    4: "UPDATE EVENT",
  },

  SCANNINGBEFORE:{
    1: "15 Min",
    2: "30 Min",
    3: "1 hour",
    4: "2 hour",
    5: "4 hour",
  },

  // HOMEPAGE_LAYOUT_TYPE:[
  //   {"id":1,"name":"NORMAL LIST"},
  //   {"id":2,"name":"CARD LIST"},
  //   {"id":3,"name":"SMALL GRID"},
  //   {"id":4,"name":"MEDIUM GRID"},
  //   {"id":5,"name":"LARGE GRID"},
  //   {"id":6,"name":"BANNER"},
  //   {"id":7,"name":"CURVED CARD"},
  //   {"id":8,"name":"CAPSULE CARD"},
  //   {"id":9,"name":"SQUIRCLE CARD"},
  // ],

  HOMEPAGE_LAYOUT_OBJECT: {
    "NORMAL LIST": 1,
    "CARD LIST": 2,
    "SMALL GRID": 3,
    "MEDIUM GRID": 4,
    "LARGE GRID": 5,
    "BANNER": 6,
    // "CURVED CARD": 7,
    // "CAPSULE CARD": 8,
    // "SQUIRCLE CARD": 9,
  },

  // HOMEPAGE_LAYOUT_OBJECT: {
  //   1 : "NORMAL LIST",
  //   2 :"CARD LIST",
  //   3 :"SMALL GRID",
  //   4 :"MEDIUM GRID",
  // },

  // WEBAPP_HOMEPAGE_LAYOUT:[
  //   {"id":1,"name":"SMALL BOX"},
  //   {"id":2,"name":"MEDIUM BOX"},
  //   {"id":3,"name":"LARGE BOX"},
  //   {"id":4,"name":"BANNER VIEW"},
  //   {"id":5,"name":"VERTICAL CARD"},
  //   {"id":6,"name":"VERTICAL CARD BG"},
  //   {"id":7,"name":"TICKET CARD"},
  // ],

  WEBAPP_HOMEPAGE_OBJECT : {
    "SMALL BOX" : 1,
    "MEDIUM BOX" : 2,
    "LARGE BOX" : 3,
    "BANNER VIEW" : 4,
    "VERTICAL CARD" : 5,
    "VERTICAL CARD BG" : 6,
    "TICKET CARD" : 7
  },

  

  HOMEPAGE_CONTENT_TYPE:[
    {"id":1, "name": "SPECIFIC EVENT"},
    // {"id":2, "name": "CATEGORY"}
    {"id":2, "name": "Organiser"},
    {"id":3, "name": "Dome"},
    {"id":4, "name": "Custom Banner"},
  ],

  HOMEPAGE_CONTENT_TYPE_OTHER:[
    {"id":1, "name": "EVENTS"},
    // {"id":2, "name": "CATEGORY"}
    // {"id":2, "name": "Organiser"},
    // {"id":3, "name": "Dome"},
    // {"id":4, "name": "Custom URL"},
  ],

  HOMEPAGE_SORTING:[
    {"id": 1, "name": "LOW TO HIGH PRICE"},
    {"id": 2, "name": "HIGH TO LOW PRICE"},
    {"id": 3, "name": "NEAREST DISTANCE"}
  ],

  // HOMEPAGE_SORTINGV2:[
  //   {"id": 1, "name": "LOW TO HIGH PRICE"},
  //   {"id": 2, "name": "HIGH TO LOW PRICE"},
  //   {"id": 3, "name": "NEAREST DISTANCE"}
  // ],

  DEEPLINKING_TYPE:[
    {"id":1,"name":"EVENT VIEW"},
    {"id":2,"name":"ORGANISER EVENTS"},
    {"id":3,"name":"CUSTOM URL"},
  ],

  DEEPLINKING_TYPE_OBJECT: {
    "EVENT_VIEW": 1,
    "ORGANISER_EVENTS": 2,
    "CUSTOM_URL": 3,
  }

}
