import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';


@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
  form

  constructor(
    private _fb: FormBuilder,
    public matDialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _http: HttpClient,
    private _commonService: CommonService,
    private _matSnackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.form = this._fb.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    })
  }

  onSubmit() {
    const data = this.form.value
    if (data.newPassword != data.confirmPassword) {
      this._matSnackBar.open('Password & Confirm Password not math', 'OK', { duration: 2000 })
      return
    }
    this._http.post(ApiEndPointUrl + '/admin/change/password', { newPassword: data.newPassword }, this._commonService.getHeader())
      .subscribe((result: any) => {
        this._matSnackBar.open(result.message, 'OK', { duration: 2000 })
        if (result.status == 200) {
          localStorage.setItem('isPasswordChangedSA', '1')
          this.matDialogRef.close(true)
        }
      })
  }

}
