import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';
import { CompanyService } from 'app/main/event-company/company.service';
import * as CryptoJS from 'crypto-js';




@Component({
  selector: 'app-admin-details',
  templateUrl: './admin-details.component.html',
  styleUrls: ['./admin-details.component.scss']
})
export class AdminDetailsComponent implements OnInit {
  adminId: any;
  adminData:any;

  plainText:string;
  encryptText: string;
  encPassword: string;
  decPassword:string;
  conversionEncryptOutput: string;
  conversionDecryptOutput:string;


  constructor(
    private _commonServices : CommonService,
    private _matDialog: MatDialog,
    private _matSnackBar:MatSnackBar,
    private _CompanyService : CompanyService,
    private matDialogRef: MatDialogRef<AdminDetailsComponent>,

    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.adminId = _data.id
    this.getAdminDetails(this.adminId);
   }

  ngOnInit(): void {
  }

  getAdminDetails(id){
    this._CompanyService.getAdminDetail(id).then((result:any) => {
      if(result.status == 200){
        this.adminData = result.data;
      }else{
        this.adminData = {}
      }
    })
  }

  onConnectBtn(id){
    this._CompanyService.onConnect(id).then((result:any) => {
      if(result.status == 200){
        window.open(`https://dashboard-stage.eventlocal.io/?0aoZtInKg4L6c0vRAkGKfA=${btoa(String(result.data.admin['email']))}`+`&srrV40Vc3bPmf1tS0xX9CQ=${btoa(String(result.data.admin['srrV40Vc3bPmf1tS0xX9CQ']))}`, "_blank");
      }
    })
  }
  


}
