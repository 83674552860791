import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { CommonService } from '@fuse/services/common.service';
import { result } from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Enums } from '@fuse/utils/systemEnums';
import { JsonPipe } from '@angular/common';
@Component({
  selector: 'app-deposit-dialog',
  templateUrl: './deposit-dialog.component.html',
  styleUrls: ['./deposit-dialog.component.scss']
})
export class DepositDialogComponent implements OnInit {

  constructor(
    private _fb: FormBuilder,
    private _commonservice: CommonService,
    private snackBar:MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DepositDialogComponent>
  ) {
    
   }

  ngOnInit() {
    
    }

  


}