import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
   
    {
        id: 'dashboards',
        title: 'Dashboard',
        type: 'item',
        icon: 'home_icon.svg',
        url: '/dashboard'
    },
    // {
    //     id: 'events',
    //     title: 'Events',
    //     type: 'item',
    //     icon: 'events.svg',
    //     url: '/event-list'
    // },
    {
        id: 'application-layout',
        title: 'Layout',
        type: 'item',
        icon: 'dashboard_customize.svg',
        url: '/application-layout'
    },
    {
        id: 'event-company',
        title: 'Event Company',
        type: 'item',
        icon: 'distributor.svg',
        url: '/event-company'
    },
    {
        id: 'events',
        title: 'Events',
        type: 'item',
        icon: 'events.svg',
        url: '/events'
    },
    {
        id: 'eventCategory',
        title: 'Manage Event Categories',
        type: 'item',
        icon: 'manage_event_categories.svg',
        url: '/event-categories'
    },
    {
        id: 'eventTypes',
        title: 'Manage Event Types',
        type: 'item',
        icon: 'manage_event_type.svg',
        url: '/event-type'
    },
    {
        id: 'tax',
        title: 'Tax Manage',
        type: 'item',
        icon: 'tax_icon.svg',
        url: '/tax'
    },
    {
        id: 'subscriptions',
        title: 'Subscriptions',
        type: 'item',
        icon: 'events.svg',
        url: '/subscriptions'
    },
    {
        id: 'sms-packages',
        title: 'SMS Packages',
        type: 'item',
        icon: 'sms_pack.svg',
        url: '/sms-packages'
    },
    {
        id: 'transactions',
        title: 'Transactions',
        type: 'item',
        icon: 'manage_event_categories.svg',
        url: '/transactions'
    },
    {
        id: 'feedback',
        title: 'Feedback',
        type: 'item',
        icon: 'reports.svg',
        url: '/feedback'
    },
    
    // {
    //     id: 'settings',
    //     title: 'Settings',
    //     type: 'item',
    //     icon: 'settings.svg',
    //     url: '/settings'
    // },
    
   
   
]
