import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { ApiEndPointUrl, ReqHeader } from '@fuse/utils/systemEnums';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  companyList = [];
  maxAvailableRecordsInTable;
  eventList = [];

  constructor(
    private _httpClient: HttpClient,
    private location: Location,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matSnackBar:MatSnackBar,
    private _CommonService : CommonService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
          this.getCompanyList({pageIndex:1,pageSize:10}),
          ]).then(
            () => {
              resolve('');
            },
          reject
        );
    })
  }

  
  /* GET COMPANY LIST */
  getCompanyList(payload){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'superAdmin/getalladmin',payload, this._CommonService.getHeader()).subscribe((response:any) =>{
        this._fuseProgressBarService.hide();
        if(response.status == 200){
          this.companyList = response.data.adminList;
          this.maxAvailableRecordsInTable = response.data.totalRecords;
        }else{
          this.companyList = [];
          this.maxAvailableRecordsInTable = 0;
        }
        resolve(response);
      }, reject)
    })
  }

  // add event company
  addCompany(payload){

    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'superAdmin/admin/AddEventCompany', payload, this._CommonService.getHeader()).subscribe((response:any) =>{
        this._fuseProgressBarService.hide();
        if(response.status == 200){
          this._matSnackBar.open(response.message, 'OK', {
            duration: 3000
          })
        }else{
          this._matSnackBar.open(response.message, 'OK', {
            duration: 3000
          })
        }
        resolve(response)
      }, reject)
    })
  }

  // get admin details
  getAdminDetail(payload){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'superAdmin/getAdminDetails', {id : payload}, this._CommonService.getHeader()).subscribe((response:any) => {
        this._fuseProgressBarService.hide();
        resolve(response);
      }, reject)
    })
  }


  // get all event
  public getEventList(payload){
    console.log("celled 02");
    
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'superAdmin/getallevent', payload, this._CommonService.getHeader()).subscribe((response:any) => {
        this._fuseProgressBarService.hide();
        if(response.status == 200){
          this.eventList = response.data.data;
          this.maxAvailableRecordsInTable = response.data.totalRecords;
        }else{
          this.eventList = [];
          this.maxAvailableRecordsInTable = 0;
        }
        resolve(response);
      }, reject)
    })
  }

  onConnect(data){
    const payload = {
      id: data
    }
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'superAdmin/connectAdminPanel', payload, this._CommonService.getHeader()).subscribe((response:any)=>{
        if(response.status == 200){
          this._matSnackBar.open(response.message , 'OK', {
            duration: 2000,
          })
        }else{
          this._matSnackBar.open(response.message , 'OK', {
            duration: 3000,
          })
        }
        resolve(response)
      }, reject)
    })
  }
  

}
