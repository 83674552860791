import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { ConfirmationDialogComponent } from './Dialog/confirmation-dialog/confirmation-dialog.component';
import { CropperComponent } from './Dialog/cropper/cropper.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatSliderModule } from '@angular/material/slider';
// import { AddVenueComponent } from './Dialog/add-venue/add-venue.component';
import { AgmCoreModule } from '@agm/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import { AddEventTypeDialogComponent } from './Dialog/add-event-type-dialog/add-event-type-dialog.component';
import { AddOrganiserDialogComponent } from './Dialog/add-organiser-dialog/add-organiser-dialog.component';
import { AddTicketTypeDialogComponent } from './Dialog/add-ticket-type-dialog/add-ticket-type-dialog.component';
import { AddGuestDialogComponent } from './Dialog/add-guest-dialog/add-guest-dialog.component';
import { AddEventCategoryDialogComponent } from './Dialog/add-event-category-dialog/add-event-category-dialog.component';
import { AssignEventDialogComponent } from './Dialog/assign-event-dialog/assign-event-dialog.component';
import { ChangePasswordDialogComponent } from './Dialog/change-password-dialog/change-password-dialog.component';
import { AuthQrDialog } from './Dialog/auth-qr-dialog/auth-qr-dialog';
import { DepositDialogComponent } from './Dialog/refund/deposit-dialog/deposit-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { OrderMembersComponent } from './Dialog/order-members-details-dialog/order-members/order-members.component';
import { MatCardModule } from '@angular/material/card';
import { MultipleDatepickerComponent } from './Dialog/event-multiple-datepicker-dialog/multiple-datepicker/multiple-datepicker.component';
import { MatChipsModule } from '@angular/material/chips';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter,} from '@angular/material-moment-adapter';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AddTaxComponent } from './Dialog/add-tax/add-tax.component';
import { AdminDetailsComponent } from './Dialog/admin-details/admin-details.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        FlexLayoutModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        FuseDirectivesModule,
        FusePipesModule,
        ImageCropperModule,
        NgxMaterialTimepickerModule,
        MatSliderModule,
        AgmCoreModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatToolbarModule,
        MatSelectModule,
        MatCardModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatChipsModule,
        MatMenuModule,
        NgxMatDatetimePickerModule,
       
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,
        
    ],
    declarations: [ConfirmationDialogComponent, AdminDetailsComponent, AddTaxComponent, MultipleDatepickerComponent,  OrderMembersComponent, CropperComponent, AddEventTypeDialogComponent,AddOrganiserDialogComponent,AddTicketTypeDialogComponent,AddGuestDialogComponent,AddEventCategoryDialogComponent,AssignEventDialogComponent,ChangePasswordDialogComponent,AuthQrDialog,
        DepositDialogComponent],
    providers: [ { provide: MAT_DATE_LOCALE, useValue: 'en' },{  provide: DateAdapter,  useClass: MomentDateAdapter,  deps: [MAT_DATE_LOCALE], }, { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },],
    entryComponents: [ConfirmationDialogComponent, CropperComponent,AddEventTypeDialogComponent,AddOrganiserDialogComponent,AddTicketTypeDialogComponent,AddGuestDialogComponent,AddEventCategoryDialogComponent,AssignEventDialogComponent,ChangePasswordDialogComponent,AuthQrDialog,
        DepositDialogComponent, AddTaxComponent, AdminDetailsComponent]
})
export class FuseSharedModule {
}
