import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { HttpClient } from '@angular/common/http';
import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'auth-qr-dialog',
  templateUrl: './auth-qr-dialog.html',
  styleUrls: ['./auth-qr-dialog.scss']
})
export class AuthQrDialog implements OnInit {
  pin = ''

  constructor(
    private _fb: FormBuilder,
    public matDialogRef: MatDialogRef<AuthQrDialog>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _http: HttpClient,
    private _commonService: CommonService,
    private _matSnackBar: MatSnackBar,
    private _router: Router
  ) { }

  ngOnInit() {
    console.log('data', this._data)
  }

  onSubmit() {
    this._http.post(ApiEndPointUrl + 'admin/verify/authuser', { token: this.pin, setUpAuthy: +(!this._data.isAuthySetUp) }, this._commonService.getHeader())
      .subscribe(async (result: any) => {

        if (result.status == 200) {
          localStorage.removeItem('userToken');
          localStorage.setItem('isAuthySetUpSA', '1')
          localStorage.setItem('userTokenSA', result.data.admin.token)

          this.matDialogRef.close()

          setTimeout(() => {
            this._router.navigate(['/dashboard']);
          }, 1500);

        } else {
          this._matSnackBar.open(result.message, 'OK', { duration: 2000 })
        }
      })
  }

}
