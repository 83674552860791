import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';


@Injectable({
  providedIn: 'root'
})
export class AddEventCategoryService {

  constructor(
    private _http: HttpClient,
    private _matSnackBar: MatSnackBar,
    private _fuseProgressBarService: FuseProgressBarService,
    private _commonService: CommonService
  ) { }

  addCategory(data) {
    this._fuseProgressBarService.show()
    return new Promise((resolve, reject) => {
      this._http.post(ApiEndPointUrl + 'event/add/category', data, this._commonService.getHeader('formData')).subscribe((result: any) => {
        this._fuseProgressBarService.hide()
        if (result.status == 200) {
          resolve(result)}
        this._matSnackBar.open(result.message, 'OK', {
          verticalPosition: "bottom",
          duration: 2000
        })
      }, err => {
        this._fuseProgressBarService.hide()
        reject(err)
      })
    })
  }
  
  editCategory(data) {
    // console.log("edit category method called",data)
    // let formData = new FormData()
    // formData.append("title", data.title)
    // formData.append("description", data.description)
    // formData.append("icon", data.icon)
    // formData.append("categoryId", id)
    // formData.append("isApprove", data.isApprove)
    // formData.append("backgroundImage", data.background)

    this._fuseProgressBarService.show()
    return new Promise((resolve, reject) => {
      this._http.post(ApiEndPointUrl + 'event/edit/category', data, this._commonService.getHeader('formData')).subscribe((result: any) => {
        this._fuseProgressBarService.hide()
        if (result.status == 200) {
          resolve(result)
        }
        this._matSnackBar.open(result.message, 'OK', {
          verticalPosition: "bottom",
          duration: 2000
        })
      }, err => {
        this._fuseProgressBarService.hide()
        reject(err)
      })
    })
  }
}


