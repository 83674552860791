import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation, } from "@angular/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogClose, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { forEach } from "lodash";

// import { MatDatepicker, MatDatepickerInputEvent, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: "app-multiple-datepicker",
  templateUrl: "./multiple-datepicker.component.html",
  styleUrls: ["./multiple-datepicker.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MultipleDatepickerComponent implements OnInit {
  selectedDate;
  allSelectedDates;
  minDate = new Date();
  setSchedule: FormGroup;
  timeSlotEnable: boolean = false;
  timeClickToHide: boolean = false;
  timeBtnHide: boolean = false;
  dates: moment.Moment[] = [];
  myDateChips: any = [];

  rowLength: any;

  finalData = [];
  allTimeSlot = [];
  allDates:any = [];
  maxDate: Date;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<MultipleDatepickerComponent>,
    private cd : ChangeDetectorRef
  ) {
    
    
  }
  ngOnInit() {
   
  }

  isSelected = (event: any) => {
    const date = event as moment.Moment;
    return this.dates.find((x) => x.isSame(date)) ? "selected" : null;
  };

  select(event: any, calendar: any) {
    const date: moment.Moment = event;
    const index = this.dates.findIndex((x) => x.isSame(date));

    if (index < 0) {
      this.dates.push(date);
      this.myDateChips.push(date.format("YYYY/MM/DD"));

      if (this.myDateChips.length == 0) {
        this.timeSlotEnable = false;
      } else {
        this.timeSlotEnable = true;
      }
    } else {
      this.dates.splice(index, 1);
      this.myDateChips.splice(index, 1);

      if (this.myDateChips.length == 0) {
        this.timeSlotEnable = false;
      } else {
        this.timeSlotEnable = true;
      }
    }
    calendar.updateTodaysDate();
  }

  get timeSlotes(): FormArray {
    return this.setSchedule.controls["timeSlotes"] as FormArray;
  }

  addTimeSlot() {
    
    this.timeClickToHide = true;
    this.timeBtnHide = true;

    this.setSchedule = this._fb.group({
      dates: [this.myDateChips, Validators.required],
      timeSlotes: this._fb.array([]),
    });
  }

  addRow(i) {
    (<FormArray>this.setSchedule.get('timeSlotes')).push(
      this._fb.group({
        startTime: ["", Validators.required],
        endTime: ["", Validators.required],
      })
    );

    this.rowLength = this.setSchedule.get("timeSlotes")["controls"].length - 1;
    
  }
  multiPrint(){
    console.log("printed value here ->>", this.setSchedule);
  }

  removeRow(i) {
    console.log(
      "fo find time slote data ->",
      this.setSchedule.get("timeSlotes")["controls"][i]
    );
    (<FormArray>this.setSchedule.get("timeSlotes")).removeAt(i);

    this.rowLength = this.setSchedule.get("timeSlotes")["controls"].length - 1;
  }

  goBack() {
    this.timeBtnHide = false;
  }

  remove(item: any): void {
    const index = this.myDateChips.indexOf(item);
    if (index >= 0) {
      this.dates.splice(index, 1);
      this.myDateChips.splice(index, 1);
    }
  }

  onSubmit() {
    //to get all dates valuse
    for (let i = 0; i < this.setSchedule.get("dates").value.length; i++) {
      this.finalData.push({salectedDate: this.setSchedule.get("dates")["value"][i]});
    }

    //to get all time slot valuse
    for (let i = 0; i < this.setSchedule.get("timeSlotes")["controls"].length; i++) {
      this.allTimeSlot.push(this.setSchedule.get("timeSlotes")["controls"][i].value);
    }

    
    for (let i = 0; i < this.setSchedule.get("timeSlotes")["controls"].length; i++) {
      this.finalData.forEach((element) => {
        element.timeSlot = this.allTimeSlot;
      });  
    }
    
    /* find max and min dates for ticket saleing date */
    for (let i = 0; i < this.setSchedule.value['dates'].length; i++) {
      this.allDates.push(this.setSchedule.value['dates'][i]);
      // console.log("all dates", this.setSchedule.value['dates']);
    }
    /* find Max date */
    this.maxDate = new Date(Math.max(...this.allDates.map(element =>{
      return new Date(element)
      })
    ))

    /* find Min Date */
    this.minDate = new Date(Math.min(...this.allDates.map(element =>{
      return new Date(element)
      })
    ))

    /* push the max and min date */
    this.finalData.forEach(element => {
      element.maxDate = this.maxDate;
      element.minDate = this.minDate;
    });
    
    if(this.finalData){
      this.dialogRef.close(this.finalData);
    }else{
      this.dialogRef.close('');
    }

  }

  closeDialog(){
    if(this.finalData){
      this.dialogRef.close(this.finalData);
    }else{
      this.dialogRef.close();
    }
  }
}
