import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { ApiEndPointUrl } from '../../../../@fuse/utils/systemEnums';

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router"
import { ChangeProfileDialogComponent } from './change-profile-dialog/change-profile-dialog.component';
import { NotificationListService } from 'app/main/notification-lists/notification-list.service';


@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
    
})

export class ToolbarComponent implements OnInit, OnDestroy {
    [x: string]: any;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    userName: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    email: string
    unReadNotificationList:any;
    unReadNotiNum;
    unReadNotiLength;
    unReadNoti;
   
    unReadToRead;
    toCreateOrderLink;
    todayDate = new Date();
    // todayDate = new Date(new Date().setDate(new Date().getDate() - 3));
    
    allDates;
    todayNotification;
    notificationId;
    userId = localStorage.getItem('idSA');

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        public _dialog: MatDialog,
        private _httpClient: HttpClient,
        private _matSnackBar: MatSnackBar,
        private _router: Router,
        private _notificationListService : NotificationListService
    ) {
        this.getUnreadNotiList();
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];



        this.navigation = navigation;
        this.userName = localStorage.getItem('userNameSA');
        this.email = localStorage.getItem('userEmailSA');
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
    }
    notiBtn(e){
        console.log("this is the element value", e.pushType);
        this.notificationId = e.id;
        if(e.pushType == 1){
            this._router.navigate(['/distributors']);
            console.log("distributors condition is true");
        }else if(e.pushType == 2){
            this._router.navigate(['/events']);
            console.log("events condition is true");
        }else if(e.pushType == 3){
            this._router.navigate(['/orders']);
            console.log("orders condition is true");
        }else if(e.pushType == 4){
            this._router.navigate(['/events']);
            console.log("events condition is true");
        }else{
            return;
        }
        this.getClickedUnreadNoti();
        this.getUnreadNotiList();

    }
    getClickedUnreadNoti(){
        const payload = {
            "userId": this.userId,
            "id": this.notificationId,
        }
        this._notificationListService.getReadNotification(payload).then((result: any) =>{
            console.log("working okay");
        })
    }

    notiReaded(){
        // this.unReadNoti = this.unReadNoti.map((obj:any)=>{
        //     return {...obj, isRead : 1}
        // });
        //     this.readNoti = this.unReadNoti.map((obj:any)=>{return {...obj, isRead : 1}
        // });
        // console.log("this.unReadToRead", this.readNoti);
        // console.log("all entryes", this.unReadNoti);
    }

    /* get 10 unread notification list */
    getUnreadNotiList(){
        
    // console.log("onclick funcation run")
    this._notificationListService.getAllNotiLitst().then((result:any) => {
        // console.log("working here");
        if (result.status == 200) {
            this.unReadNotificationList = result.data.transactionsList
            // this.unReadNotiNum =  this.unReadNotificationList;
            this.unReadNoti = this.unReadNotificationList.filter(item => item.isRead == 0)
            this.unReadNotiLength = this.unReadNoti.length; 
            
            // to find today word from date
            this.allDates = this.unReadNotificationList.map(item => new Date(item.createdDate));
            
            var todayDateDay =  this.todayDate.getDate();
            
            this.allDates = this.allDates.map(item => item.getDate());
            
            this.todayNotification = this.allDates.map(item => item == todayDateDay);
            
        }else{
            
        }
        })
    }
    
    // goRouterLink(i){
    //     console.log("create order link ->", i);
    //     if(this.toCreateOrderLink){
    //         this._router.navigate(['/orders']);
    //     }
    //     this.readNoti = this.unReadNoti.find(i => i.isRead = 1)
    //     console.log("this.readNoti", this.readNoti);   
    // }
    

    logout() {
        localStorage.clear()
        this._router.navigate(['/auth/login']);
    }
    openChangePassDialog() {
        const changePassDialog = this._dialog.open(ChangePasswordDialogComponent,  { width: '400px', disableClose: true });
        changePassDialog.afterClosed().subscribe(data => {
            var header = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('userTokenSA')
            });
            
            if (data[1]) {
                var body = {
                    'oldPassword': data[1].oldPass,
                    'newPassword': data[1].newPass,
                }
                this._httpClient.put(ApiEndPointUrl + 'admin/change-password', body, { headers: header })
                    .subscribe((response: any) => {
                        this._matSnackBar.open(response.message, 'OK', {
                            verticalPosition: 'bottom',
                            duration: 2000
                        });

                    }, error => {
                        this._matSnackBar.open(error.message, 'error', {
                            
                            verticalPosition: 'bottom',
                            duration: 2000
                        });
                    }

                    );
            }
        })
    }

    openChangeProfileDialog() {
        const changeProfileDialog = this._dialog.open(ChangeProfileDialogComponent, { width: '400px', disableClose: true });
        changeProfileDialog.afterClosed().subscribe(data => {
            var header = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('userTokenSA')
            });
            if (data[1]) {
                var body = {
                    "name": data[1].newName
                }
                this._httpClient.put(ApiEndPointUrl + 'admin/edit-profile', body, { headers: header })
                    .subscribe((response: any) => {
                        this._matSnackBar.open(response.message, 'OK', {
                            verticalPosition: 'bottom',
                            duration: 2000
                        });
                        localStorage.setItem('userNameSA', response.data.name)
                        this.userName = localStorage.getItem('userNameSA');
                    }, error => {
                        this._matSnackBar.open(error.message, 'error', {
                            verticalPosition: 'bottom',
                            duration: 2000
                        });
                    }

                    );
            }   
        })
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

  

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }
}
