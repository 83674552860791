import { Injectable } from '@angular/core';
import { ApiEndPointUrl, ReqHeader } from '@fuse/utils/systemEnums';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { resolve } from 'url';
import { reject, result } from 'lodash';
import { promise } from 'protractor';
import { BehaviorSubject, Observable, ObservableInput } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    countryCode;
    defaultCountryCode = 91;
    allCountries;
    allCurrencies
    handleError: (err: any, caught: Observable<Object>) => ObservableInput<any>;
    constructor(
        private _httpClient: HttpClient,
        private location: Location,
        private _fuseProgressBarService: FuseProgressBarService,
        private _matSnackBar: MatSnackBar,
    ) {

    }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getAllCountries(),
                this.getAllCurrencies(),
            ]).then(
                () => {
                    resolve('');
                },
                reject
            );
        })
    }



    reqHeadersWithToken = new HttpHeaders({
        'Authorization': localStorage.getItem('userTokenSA'),
        'Content-Type': 'application/json'
    });


    /* GET ALL COUNTRIES */
    getAllCountries() {
        return new Promise((resolve, reject) => {
            this._httpClient.get(ApiEndPointUrl + 'admin/getall/countries', this.getHeader())
                .subscribe((result: any) => {
                    if (result.status == 200) {
                        this.countryCode = result.data.countries
                        this.allCountries = result.data.countries;
                        resolve(result.data.countries)
                    }
                }, reject)
        })
    }


    getAllCurrencies() {
        return new Promise((resolve, reject) => {
            this._httpClient.get(ApiEndPointUrl + 'admin/getall/currencies', this.getHeader())
                .subscribe((result: any) => {
                    if (result.status == 200) {
                        this.allCurrencies = result.data.currencies;
                        resolve(result.data.currencies)
                    }
                }, reject)
        })
    }

    /* GET ALL STATES */
    getStates(countryId) {
        return new Promise((resolve, reject) => {
            this._httpClient.post(ApiEndPointUrl + 'admin/getall/state', { countryId: countryId }, this.getHeader())
                .subscribe((result: any) => {
                    if (result.status == 200) {
                        resolve(result);
                    } else
                        reject(result)
                }, reject)
        })
    }


    pass(payload) {
        return new Promise((resolve, reject) => {
            this._httpClient.post(ApiEndPointUrl + 'customer/resetPassword', payload, this.getHeader())
                .subscribe((response: any) => {
                    if (response.status == 200)
                        resolve(response);
                    else
                        reject(response)
                }, reject);
        });
    }


    /* BACK FUNCTION */
    goBack() {
        this.location.back();
    }

    /* GET CURRENCY SYMBOL FOR WHOLE PROJECT*/
    getCurrencySymbol() {
        return localStorage.getItem("symbol")
    }

    /* GET HEADER  */
    getHeader(headerType?: 'formData') {
        if (headerType === 'formData') {
            return {
                headers: new HttpHeaders({
                    'Authorization': localStorage.getItem('userTokenSA')
                })
            }
        } else
            return {
                headers: new HttpHeaders({
                    'Authorization': localStorage.getItem('userTokenSA'),
                    'Content-Type': 'application/json'
                })
            }
    }

    public postFormDataWithHeader<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                // 'Content-Type':  'multipart/form-data',
                // observe: 'events',
                Authorization: localStorage.getItem('userTokenSA'),
            }),
            reportProgress: true,
        };
        return this._httpClient.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }


    // Presigned URL
    getPresignedUrlAPI(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(ApiEndPointUrl + 'common/presignedUrl', body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    updateInPresignedUrlAPI(url, contenttype, file) {
        this._fuseProgressBarService.show();
        const headers = new HttpHeaders({
            'Content-Type': contenttype,
        });
        return new Promise((resolve, reject) => {
            this._httpClient.put(url, file, { headers }).subscribe((response: any) => {
                resolve(true);
                this._fuseProgressBarService.hide();
            }, reject);
        })
    }

    getFileUploadStatus(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(ApiEndPointUrl + 'common/updateFileStatus', body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }


}